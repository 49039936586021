const stats = [
    { label: "Sponsored posts we've assisted", value: '25k +' },
    { label: "Put in creators' pockets faster", value: '$2M +' },
    { label: "Creators we've worked with", value: '2.5k +' },
  ]
  
  export default function About() {
    return (
      <div className="bg-white py-32" id="About">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our mission</h1>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600">
                We are a team of creators, managers, and internet entrepreneurs who have been in the creator economy for nearly a decade.
                </p>
                <p className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                We understand that paid promotional income is critical to creators. By getting this cash in your hands faster we will create a stronger, more trustworthy, and more reliable creator economy. Don't just take our word for it, we let our track record speak for itself.
                </p>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  