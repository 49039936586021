import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "How does this work?",
    answer:
      "Our process begins with creators using our submission form to provide us with information related to their sponsored post. Our team then reviews your situation, and identifies how we can help. You can expect to hear from us after a couple of days with an offer or plan of action.",
  },
  {
    question: "How much does this cost?",
    answer:
      "We come at no immediate out of pocket cost to creators. We typically collect 5-10% of the total sponsorship value after we successfully get your sponsorship paid out.",
  },
  {
    question: "Can I do this with more than one of my outstanding sponsored posts?",
    answer:
      "Yes, absolutely! We are happy to announce that most of our creator partners are returning clients!",
  },
  {
    question: "Do you have a referral program?",
    answer:
      "Yes, please contact us at outreach@getcreatorfinance.com to learn more about becoming a referral partner.",
  },
  // More questions...
]

export default function Faqs() {
  return (
    <div className="bg-white" id="FAQs">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h1 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h1>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
