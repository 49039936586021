import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Services from './components/services';
import About from './components/about';
import Footer from './components/footer';
import Testimonials from './components/testimonials';
import Faqs from './components/faqs';
import Cta from './components/cta';
import NewsLetter from './components/newsletter';
import ReactGA from "react-ga4";


function App() {
  ReactGA.initialize("G-BJTBQ6FZRW");
  return (
    <>
      <Header></Header>
      <Services></Services>
      <About></About>
      {/* <Testimonials></Testimonials> */}
      <Faqs></Faqs>
      <Cta></Cta>
      <NewsLetter></NewsLetter>
      <Footer></Footer>
    </>
  );
}

export default App;
