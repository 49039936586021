import { CheckIcon, ForwardIcon, ShieldCheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Quick-Pay',
    description:
      "We expedite payment for promos so you don’t have to wait - get your hard-earned cash within 2 business days.",
    href: 'https://creator-finance.com#About',
    icon: ForwardIcon,
  },
  { 
    name: 'Invoice Correction',
    description:
      'Brands, agencies and labels alike can have specific and complicated vendor onboarding processes. Sometimes this can hold the payment process up. We have experienced accounting teams dedicated to make sure this process is completed quickly and correctly.',
    href: 'https://creator-finance.com#About',
    icon: CheckIcon,
  }, 
  {
    name: 'Debt Collection',
    description:
      "It's a sad truth that sometimes brands take advantage of creators. Our creator payments support team can take action to get you what’s owed.",
    href: 'https://creator-finance.com#About',
    icon: ShieldCheckIcon,
  },
]

export default function Services() {
  return (
    <div className="bg-white py-24 sm:py-32" id="Services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="text-base font-semibold leading-7 text-indigo-600">Our Services</h1>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How we get you paid faster
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Not all paid promos are the same, but most of our services can be categorized into the following areas:
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
