export default function Cta() {
    return (
      <div className="bg-white" id="Start">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Get paid faster.
              <br />
              Submit an invoice today.
            </h1>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              The first step to get you paid for your sponsored post faster is sending over some information about the post.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://airtable.com/shrcMwQePFaKLtkit"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                target="_blank" rel="noreferrer"
              >
                Get started
              </a>
              {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  